import React, { AnchorHTMLAttributes } from "react";
import {
  AmitLink,
  GradientLink,
  PrimaryAnchor,
  PrimaryLink,
  SecondaryAnchor,
  SecondaryLink,
  UnderlineLink,
} from "./link.styles";

interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: "primary" | "secondary" | "gradient" | "underline" | "amit";
  external?: boolean;
}

export const CustomLink = ({
  children,
  variant,
  external = false,
  ...props
}: ILinkProps) => {
  if (props.href && props.href.startsWith("//")) {
    // Remove first slash
    props.href = props.href.substring(1);
  }
  if (variant === "secondary") {
    if (external) {
      return <SecondaryAnchor {...props}>{children}</SecondaryAnchor>;
    }
    return (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      <SecondaryLink {...(props as any)}>{children}</SecondaryLink>
    );
  }

  if (variant === "gradient") {
    return (
      <GradientLink {...(props as any)}>
        <div className="text-overlay">{children}</div>
      </GradientLink>
    );
  }

  if (variant === "amit") {
    return <AmitLink className="no-external-colors" {...(props as any)}>{children}</AmitLink>;
  }

  if (variant === "underline") {
    return <UnderlineLink {...(props as any)}>{children}</UnderlineLink>;
  }
  if (external) {
    return <PrimaryAnchor {...props}>{children}</PrimaryAnchor>;
  }
  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <PrimaryLink {...(props as any)}>{children}</PrimaryLink>
  );
};
