import styled from "styled-components";
import Link from "next/link";
import { CSSVars } from "../../styles/styles.config";

export const PrimaryLink = styled(Link)`
  cursor: pointer;
  background-color: ${CSSVars.blue500};
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 15px;
  transition: 0.2s;
  font-weight: 500;
  font-size: ${CSSVars.fontSizeMD};
  font-family: ${CSSVars.primaryFont};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;

  &:hover {
    background-color: ${CSSVars.blue700};
    color: white;
  }

  &:active {
    background-color: ${CSSVars.blue300};
    color: white;
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
`;

// Use the 'as' prop to render an 'a' tag for anchor elements
export const PrimaryAnchor = styled(PrimaryLink).attrs({ as: "a" })``;

export const SecondaryLink = styled(PrimaryLink)`
  background-color: transparent;
  border: 1px solid ${CSSVars.blue500};
  color: ${CSSVars.blue500};

  &:hover {
    background-color: ${CSSVars.blue500};
    color: white;
  }

  &:active {
    background-color: ${CSSVars.blue300};
    border-color: ${CSSVars.blue300};
    color: white;
  }
`;

export const SecondaryAnchor = styled(SecondaryLink).attrs({ as: "a" })``;

export const TransparentLink = styled(PrimaryLink)`
  background-color: transparent;
  border: none;
  color: ${CSSVars.gray500};

  &:hover {
    background-color: transparent;
    color: ${CSSVars.gray300};
  }

  &:active {
    background-color: transparent;
    border-color: ${CSSVars.gray100};
    color: ${CSSVars.gray100};
  }
`;

export const TransparentAnchor = styled(TransparentLink).attrs({ as: "a" })``;

export const GradientLink = styled(Link)`
  background-color: ${CSSVars.blue500};
  position: relative;
  padding: 12px 30px;
  margin: 0 auto 30px;
  border-radius: 8px;
  border: none;

  font-weight: 300;
  font-size: ${CSSVars.fontSizeMD};
  color: white;
  
  background-color: ${CSSVars.blue500};
  
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  width: fit-content;
  
  overflow: hidden;
  
  @media screen and (min-width: ${CSSVars.breakpointMD}){
    font-size: ${CSSVars.fontSizeLG};
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 101%;
    width: 100%;
    height: 100%;

    transition: 0.2s;

    z-index: 1; /* Ensure it’s below the text */
    background: linear-gradient(270deg, #ff979c 0%, #4c4fff 100%);
  }

  &:hover {
    &::before {
      transform: translateX(-100%);
    }
  }

  .text-overlay {
    z-index: 2;
  }
`;

export const UnderlineLink = styled(Link)`
  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  margin: 30px auto 50px;
  width: max-content;

  color: ${CSSVars.blue500};
  font-size: ${CSSVars.fontSizeMD};
  font-weight: 500;

  &::before {
    content: "";
    position: absolute;
    top: calc(100% + 2px);
    left: -2px;
    width: 0;
    height: 1px;
    border-radius: 5px;
    background-color: ${CSSVars.blue500};
    transition: 0.3s;
  }

  &:hover {
    &::before {
      width: 100%;
    }
  }
`;

export const AmitLink = styled(PrimaryLink)`
  background-color: #f5f5ff;
  color: #202041;
  border: none;

  &:hover,
  &:active {
    background-color: #e4e4ff;
    color: #202041;
  }
`;
